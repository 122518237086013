<template>
    <div v-if="!loading">
        <zw-table ref="table"
                  tableName="entity_statuses"
                  columnsPrefix="entity-status.column."
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :sticky="false"
                  sticky-header="100%"
                  :actions-list="getActions()"
        >
            <template #cell(color)="row">
                <b-icon v-if="row.item.color" icon="hexagon-fill" :style="'color:'+row.item.color"></b-icon>
            </template>
            <template #cell(actions)="row">

                <b-dropdown class="mb-1"
                            left
                            variant="primary"
                            size="sm"
                >
                    <template #button-content>
                        {{ $t('common.label.actions') }}
                    </template>

                    <b-dropdown-item
                        @click="$root.$children[0].openModal('entity-status-modal', {id: row.item.id},refreshTable,{width: '800px'})">
                        <font-awesome-icon class="mr-2" icon="edit"/>
                        {{ $t('common.title.edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="!row.item.base_status"
                        @click="deleteStatus(row.item.id)">
                        <font-awesome-icon class="mr-2" icon="trash"/>
                        {{ $t('common.title.delete') }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Settings-statuses',
    mixins: [commonSave, commonTable],
    data() {
        return {
            loading: true,
            statuses: [],
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('EntityStatus', ['getStatusesTable']),
        shown() {
            this.loading = false
            this.$root.afterSettingsUpdate()
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'EntityStatus/fetchStatuses', 'getStatusesTable');
        },
        afterSave() {
            this.$store.dispatch('CommonData/fetchCommonData')
            this.shown()
            this.$root.afterSettingsUpdate()
        },
        deleteStatus(id) {
            this.$removeConfirm('EntityStatus/deleteStatus', id, this.refreshTable)
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('entity-status-modal', {id: 0}, this.refreshTable, {width: '800px'})
                    }
                }
            ]
            return actions
        }
    },
    mounted() {
        this.shown()
    }
}
</script>